
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CookiePopup extends Vue {
  @Prop() private STRINGS!: any;

  mounted() {
    this.initCookieContainer();
  }

  public acceptCookies() {
    this.$store.commit("acceptBasicCookieConsent", this);
    this.$store.commit("acceptGAConsent", this);
    this.initCookieContainer();
  }

  public closeCookieContainer() {
    const popupContainer: HTMLElement | null = document.getElementById("cookiePopupContainer");
    if (popupContainer) {
      popupContainer.classList.add("cookiePopupHide");
    }
    (<any>this).$gtag.event("close", {event_category: "cookiePopup", event_label: this.STRINGS.ROUTE_NAMES.language});
  }

  public initCookieContainer() {
    if (this.$store.state.basicCookieConsentGiven) {
      const popupContainer: HTMLElement | null = document.getElementById("cookiePopupContainer");
      if (popupContainer) {
        popupContainer.classList.add("cookiePopupHide");
      }
    }
  }

  public navigateToPrivacyPolicy() {
    // TODO: maybe change to open in new tab
    this.$router.push(this.STRINGS.ROUTE_NAMES.privacyPolicy);

  }
}
