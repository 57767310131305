import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/de/",
      name: "home_de",
      component: () => import(/* webpackChunkName: "about" */ "./views/Home/Home_de.vue"),
    },
    {
      path: "/privacyPolicy/",
      name: "privacyPolicy",
      component: () => import(/* webpackChunkName: "about" */ "./views/PrivacyPolicy/PrivacyPolicy.vue"),
    },
    {
      path: "/de/privacyPolicy/",
      name: "de_privacyPolicy",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ "./views/PrivacyPolicy/PrivacyPolicy_de.vue"),
    },
    {
      path: "/legal/",
      name: "legal",
      component: () => import(/* webpackChunkName: "about" */ "./views/Legal/Legal.vue"),
    },
    {
      path: "/de/legal/",
      name: "de_legal",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ "./views/Legal/Legal_de.vue"),
    },
    {
      path: "/businessTerms/",
      name: "businessTerms",
      component: () => import(/* webpackChunkName: "about" */ "./views/BusinessTerms/BusinessTerms.vue"),
    },
    {
      path: "/de/businessTerms/",
      name: "de_businessTerms",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ "./views/BusinessTerms/BusinessTerms_de.vue"),
    },
    {
      path: "/apps/privacyPolicy/",
      name: "apps_privacyPolicy",
      component: () => import(/* webpackChunkName: "about" */ "./views/PrivacyPolicy_App/PrivacyPolicy.vue"),
    },
    {
      path: "/de/apps/privacyPolicy/",
      name: "de_apps_privacyPolicy",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ "./views/PrivacyPolicy_App/PrivacyPolicy_de.vue"),
    },
    {
      path: "/apps/legal/",
      name: "apps_legal",
      component: () => import(/* webpackChunkName: "about" */ "./views/Legal_App/Legal.vue"),
    },
    {
      path: "/de/apps/legal/",
      name: "de_apps_legal",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ "./views/Legal_App/Legal_de.vue"),
    },
    {
      path: "*",
      component: Home
    }
  ],
});
