/**
 * Object containing english frontend strings, should be the same as in webapp/src/constants/strings_frontend_en.ts
 */
const TRANSLATIONS = {
  ROUTE_NAMES: {
    languages: {
      default: "en",
      other: ["de"]
    },
    language: "en",
    home: "/",
    about: "/about/",
    legal: "/legal/",
    privacyPolicy: "/privacyPolicy/",
    businessTerms: "/businessTerms/",
    legal_app: "/apps/legal/",
    privacyPolicy_app: "/apps/privacyPolicy/",
    wheelsRepo: "/apps/wheelsRepo/"
  },
  NOTIFICATIONS: {
    error: {
      header: "Error"
    },
    info: {
      header: "Info"
    }
  },
  VALIDATION: {
    letters: `characters`,
    hasToBe: `has to be between`,
    empty: `has to be empty or between`,
    and: `and`,
    validEmail: `has to be a valid email address`,
  },
  APPS: {
    description: "Description",
    stores: "Stores",
    wheelsRepo: {
      title: "WheelsRepo - Vehicle Expenses",
      description: "WheelsRepo is the simplest and easiest vehicle expenses and fuel manager you can find. " +
        "You can manage all expenses of your vehicles (car, motorcycles, etc.) and get detailed statistics around fuel " +
        "consumption and general costs per distance.",
      storeHref: {
        apple: "https://apps.apple.com/us/app/wheelsrepo-vehicle-expenses/id1481989957?mt=8"
      },
      storeButtonStyle: {
        apple: {
          "display": "inline-block",
          "overflow": "hidden",
          "background": "url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-10-04&kind=iossoftware" +
            "&bubble=ios_apps) no-repeat",
          "width": "135px",
          "height": "60px",
          "background-position": "center"
        }
      },
      storeBadgeSource: {
      }
    }
  },
  SERVICES: {
    startup: {
      title: "Startup",
      desc: "You have an early startup and need software developed but the cash is tight? Talk to us and we find the " +
        "best solution for your needs.",
      imgAlt: "Startup Icon"
    },
    custom: {
      title: "Custom Software",
      desc: "We build everything that can be built and even more, from small business software to big projects. With " +
        "Lines of Future Solutions you’re at a top spot for individual software development.",
      imgAlt: "Software Icon"
    },
    consulting: {
      title: "IT Consulting",
      desc: "You need something but don’t know what exactly? You have an idea yourself and need help? You want to join the future? " +
        "We help you with every concern of information technologies from architecture to security. Work with us so we can make the " +
        "best out of it.",
      imgAlt: "Consulting Icon"
    },
    blockchain: {
      title: "Blockchain & Smart Contracts",
      desc: "From smart contract creation and testing to custom blockchain development, with us you can see yourself top-positioned " +
        "in this emerging market.",
      imgAlt: "Blockchain & Smart Contracts Icon"
    },
    webapp: {
      title: "Web & App Development",
      desc: "From small websites using CMS to big customized business applications, everything is possible with us starting at a " +
        "low budget.",
      imgAlt: "Web and App Development Icon"
    },
    cloud: {
      title: "Cloud",
      desc: "Clouds are not only there to block the sun and bring rain! If you need help moving your existing infrastructure into the " +
        "cloud or want to build your new application on cloud technologies, talk to us.",
      imgAlt: "Cloud Icon"
    },
    ai: {
      title: "Machine Learning",
      desc: "The world is moving on faster and faster with artificial intelligence and self-learning machines to help " +
        "meeting customer needs and gain insight into unstructured big data. We get the most out of your data.",
      imgAlt: "Machine Learning Icon"
    },
    security: {
      title: "Security",
      desc: "The more complex a software gets, the more difficult it is to properly secure it. We at Lines of Future Solutions help " +
        "you with testing and improving your companies security strategy.",
      imgAlt: "Security Icon"
    },
  },
  QUOTES: {
    cite_arthur_c_clarke: "Any sufficiently advanced technology is indistinguishable from magic.",
    arthur_c_clarke: "Arthur C. Clarke",
    cite_stewart_brand: "Once a new technology rolls over you, if you’re not part of the steamroller, you’re part of the road.",
    stewart_brand: "Stewart Brand",
    cite_toni_morrison: "If there's a book that you want to read, but it hasn't been written yet, then you must write it.",
    toni_morrison: "Toni Morrison"
  },
  STRINGS: {
    str_test: "English test message",
    home: "Home",
    about: "About Us",
    services: "Services",
    partner: "Partner",
    portfolio: "Portfolio",
    apps: "Apps",
    whoWeAre: "Who we are",
    wheelsRepo: "WheelsRepo",
    contact: "Contact",
    contact_us: "Contact Us",
    legal: "Legal Notice",
    legal_app: "Legal Notice Apps",
    businessTerms: "General Business Terms",
    businessTerms_long: "General Business Terms and Conditions",
    german: "German",
    english: "English",
    language: "Language",
    owner: "Owner",
    owner_name: "Dipl.-Ing. Alexander Maitz",
    visitors: "Visitors",
    vueTyperList: ["be the future", "be awesome", "be on top", "be the first"],
    vueTyperList2: ["be the future", "get what you want", "there is no impossible", "more than you thought"],
    ok: "OK",
    close: "Close",
    privacyPolicy: "Privacy Policy",
    privacyPolicy_app: "Privacy Policy Apps",
    cookieConsent: "This website uses cookies for optimization purposes. By clicking on the 'Allow Cookies' button"
      + " you accept cookies to be saved on your computer. For more information visit our privacy policy or our legal notice.",
    accept_cookies: "Allow Cookies",
    name: "Name",
    name_placeholder: "Your name here",
    subject: "Subject",
    subject_placeholder: "Enter subject here",
    email_placeholder: "Enter email here",
    tel: "Phone",
    tel_placeholder: "i.e. +43 664 123 456 789",
    budget: "Budget",
    budget_placeholder: "not given",
    budget_low: "up to 2000€",
    budget_mid: "2000€ - 5000€",
    budget_high: "5000€ - 10000€",
    budget_xl: "more than 10000€",
    message: "Message",
    message_placeholder: "Your message here",
    contact_form_invalid: "Please fill out all fields marked with * and solve reCAPTCHA",
    company_email: "office@linesoffuture.io",
    company_email_icon_alt: "Send email to Lines of Future Solutions",
    company_tel: "+43 660 432 51 49",
    company_tel_icon_alt: "Call Lines of Future Solutions",
    company_maps_link: "https://g.page/linesoffuture?share",
    company_name: "Lines of Future Solutions e.U.",
    company_name_short: "Lines of Future Solutions",
    company_location_icon_alt: "Contact Lines of Future Solutions",
    company_address: "Meisengasse 5",
    company_address_2: "2601 Eggendorf",
    company_address_3: "Austria",
    send: "Send",
    mailer_opened: "Action successful, your mail client should open shortly.",
    mail_sent: "Your message was delivered successfully.",
    additional_infos: "Additional Infos",
    showContactForm: "Drop us a message",
    contactFormPrivacyPolicyCheck: "Please read and accept the",
    accept: "Accept",
    deny: "Deny",
    about_us_text: "We at Lines of Future Solutions are digital natives who love programming and creating new ways of bringing the " +
      "future to the present. Our proficiency ranges from cloud over artificial intelligence to blockchain, and from software " +
      "architecture for complex distributed systems to simple web applications.",
    about_us_text2: "Being the " +
      "best and deliver what our customers never thought would be possible is our mission. Never be satisfied with second-best " +
      "solutions. Passion, teamwork and perfection is what we believe in. Together with the best talents in the field, we bring up " +
      "new forms of satisfaction to our customers. Always fast, always magnificent, always with an eye on efficiency and " +
      "eco-friendliness. There is no impossible!",
    slogan: "Be the future!",
    slogan_content: [["Be", "\xa0", "the"], ["future"]],
    main_title_alt: "Custom Software Engineering",
    title_content_1: [["Join", "\xa0", "our", "\xa0", "movement"], ["towards", "\xa0", "a", "\xa0", "better"],
      ["digital", "\xa0", "future", "\xa0", "now"]],
    title_1_alt: "Cloud Technologies",
    title_content_2: [["Make", "\xa0", "your", "\xa0", "way"], ["through", "\xa0", "the", "\xa0", "IT"],
      ["jungle", "\xa0", "with", "\xa0", "us"]],
    title_2_alt: "Blockchain & Smart Contracts",
    nav_logo_alt: "Lines of Future Solutions Logo",
    nav_selected_lang_en: "English language selected",
    nav_selected_lang_de: "German language selected",
    nav_select_en: "Select english",
    nav_select_de: "Select german",
    company_services: "IT Services and Consulting",
    vat: "VAT / UID No.",
    vat_number: "ATU74573115",
    company_register: "Company Register Number",
    company_register_number: "516391z",
    company_register_court: "Company Registration Court",
    company_register_court_name: "Landesgericht Wiener Neustadt",
    company_chamber_affilation: "Austrian Federal Ecomomic Chamber Affilations",
    company_chamber_affilations: "WKÖ, Fachgruppe für Unternehmensberatung, Buchhaltung und Informationstechnologie",
    commercial_law: "Commercial Law",
    commercial_law_link: "https://www.ris.bka.gv.at",
    trade_authority: "Controlling / Trade Authority",
    trade_authority_name: "Bezirkshauptmannschaft Wr. Neustadt",
    job_description: "Job Description",
    job_description_content: "Software Engineering and IT-Consulting",
    email: "Email",
    phone: "Phone",
    editorial_policy: "Editorial Policy",
    editorial_policy_content: "Informations regarding the services of",
    awarding_country: "Awarding Country",
    awarding_country_name: "Austria",
    dispute_resolution: "Customers have the possibility to direct complaints to the online dispute resolution platform of the EU",
    dispute_resolution_2: "You can also direct complaints to the following email address",
    dispute_resolution_link: "https://ec.europa.eu/odr",
    disclaimer: "Disclaimer",
    website_content: "Liability for Content on this Website",
    website_content_content: "We attempt to keep information on this website correct and topical while further developing this " +
      "website. Unfortunately we assume no liability for correctness of all information on this website, especially for " +
      "third party content. If you discover questionable or illegal content, please contact us as soon as possible. The contact details " +
      "can be found in the legal notice.",
    external_links: "Liability for Links to Third Party Websites",
    external_links_content: "Our website includes links to third party websites, whose content we are not responsible for. " +
      "We assume no liability for linked websites according to § 17 ECG, because we do not know of any illegalities and otherwise would " +
      "remove such links immediately. If you discover questionable or illegal content within our links, please contact us as soon as " +
      "possible. The contact details can be found in the legal notice.",
    copyright_notice: "Copyright Notice",
    copyright_notice_content: "The content of this website (pictures, photos, text, videos) is subject to copyright. If necessary, " +
      "we will legally pursue the unauthorized usage of content of our website.",
    privacy: "Privacy",
    privacyPolicy_content: "To view our privacy policy, please click",
    amazon_aws: "Amazon Web Services",
    amazon_aws_content: "We use Amazon Web Services (AWS) to host this website. As a result, AWS processes personal data on our instructions, and therefore we signed a data processing agreement on the basis of " +
      "Art. 28 f DSGVO (GDPR), that ensures that this company processes data it receives from us solely on our orders and abides by the DSGVO (GDPR). " +
      "The data processing agreement can be downloaded here:",
    amazon_aws_content_link: "https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf",
    google: "Google",
    google_content: "We use Google Services to secure this website and prevent attacks against our infrastructure. " +
      "As a result, Google processes personal data on our instructions, and therefore we signed a data processing agreement on the basis of " +
      "Art. 28 f DSGVO (GDPR), that ensures that this company processes data it receives from us solely on our orders and abides by the DSGVO (GDPR). " +
      "The data processing agreement can be downloaded here:",
    google_content_link: "https://business.safety.google/intl/en/adsprocessorterms/",
    google_analytics: "Google Analytics",
    google_analytics_content: "This website uses Google Analytics, a web analytics service provided by Google Inc. " +
      "(“Google”). Google Analytics uses “cookies,” which are text files placed on your computer that enable an analysis " +
      "of your use of the website. The information generated by the cookie about your use of this website is generally " +
      "transmitted to and stored on a Google server in the United States. " +
      "As IP anonymization is activated on this website, Google will, however, truncate your IP address beforehand " +
      "within Member States of the European Union or other parties to the Agreement on the European Economic Area. Only in " +
      "exceptional cases will the full IP address be transmitted to a Google server in the United States and truncated there. " +
      "On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of " +
      "the website, compiling reports on website activity and providing the website operator with other services relating " +
      "to website and internet use. The IP address transmitted by your browser within the scope of Google Analytics will not " +
      "be associated with any other data held by Google. You may refuse the use of cookies by selecting the appropriate " +
      "settings in your browser; however, please note that if you do so, you may not be able to use the full functionality " +
      "of this website. Furthermore, you can prevent Google’s collection and use of data generated by the cookie and related " +
      "to your use of the website (including your IP address) by either downloading and installing the browser plug-in available at ",
    google_analytics_content_2: " or by clicking on the following link:",
    google_analytics_content_3: "After disabling Google Analytics please delete already set cookies by yourself. " +
      "If you like to reactivate Google Analytics, which also enables cookie usage" +
      ", please click on following link:",
    google_analytics_content_link: "https://tools.google.com/dlpage/gaoptout?hl=de",
    activate_google_analytics: "Enable Google Analytics and Cookies",
    deactivate_google_analytics: "Disable Google Analytics",
    google_analytics_activated: "Google Analytics and Cookies were enabled",
    google_analytics_deactivated: "Google Analytics was disabled",

    google_analytics_content_app: "Our apps use Google Analytics, a web analytics service provided by Google Inc. " +
      "(“Google”). Google Analytics uses “cookies,” which are text files placed on your smartphone that enable an analysis " +
      "of your use of the app. The information generated by the cookie about your use of our app is generally " +
      "transmitted to and stored on a Google server in the United States. " +
      "As IP anonymization is activated for our apps, Google will, however, truncate your IP address beforehand " +
      "within Member States of the European Union or other parties to the Agreement on the European Economic Area. Only in " +
      "exceptional cases will the full IP address be transmitted to a Google server in the United States and truncated there. " +
      "On behalf of the operator of this app, Google will use this information for the purpose of evaluating your use of " +
      "the app, compiling reports on app activity and providing the app operator with other services relating " +
      "to app use. The IP address transmitted by your smartphone within the scope of Google Analytics will not " +
      "be associated with any other data held by Google. To deactivate the transmission of analytics data within our apps you can " +
      "simply deactivate it in the specific app settings. To do this, go to settings and choose 'deactivated' in the submenu " +
      "Google Analytics. Google provides more information regarding this topic under ",
    google_analytics_content_app_2: ".",
    google_admob_content: "Our Apps use Google AdSense respectively AdMob. This is a service of Google Inc., 1600 Amphitheatre " +
      "Parkway, Mountain View, CA 94043, USA, which handles including ads on websites or apps. " +
      "Google AdSense and AdMob uses cookies too. Google AdSense and AdMob additionally use Web Beacons. " +
      "Web Beacons are invisible grafics to analyize i.e. clicks and traffic on our apps. The mentioned data, your IP address and the " +
      "delivery of ads will be transmitted to and saved at Google servers in the USA. Google may pass these information to third parties " +
      "if the law demands it or if Google delegates processing to third parties. By no means Google will associate your IP address " +
      "with any other data collected by Google. Google provides further information under ",
    google_admob_content_2: " as well as under ",
    google_admob_content_3: " and under ",
    google_admob_content_4: ". This paragraph applies only to the free versions of our apps, within the Pro versions we go " +
      "strictly without ads.",
    google_admob_content_link: "https://policies.google.com/technologies/ads",
    google_admob_content_link_2: "https://policies.google.com/technologies/partner-sites",
    google_admob: "AdMob / AdSense",
    recaptcha: "Google reCaptcha",
    recaptcha_content: "In our web presence, more precisely in our contact form we use Google reCaptcha to verify that interactions " +
      "on our site are done by a real person " +
      "and not bots etc. This is a service of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, called Google in " +
      "the following. Through the certification according to the EU-US Privacy Shield",
    recaptcha_content_2: "Google ensures, that the data privacy law of the EU (GDPR) is satisfied when handling data, even when dealing " +
      "with data in the USA. Through this service, Google checks, where the request is coming from and which IP was used to do the " +
      "reCaptcha verification. Besides your IP address, possibly more data is collected for guaranteeing correct service. Legal basis " +
      "is Art. 6 Abs. 1 lit. f DSGVO. Our legitimate interest lies in the security of our web presence and in defending our website " +
      "against automated access, i.e. spam. Google offers more information regarding the usage of your data under",
    recaptcha_content_3: ".",
    recaptcha_link: "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active",
    recaptcha_link_2: "https://policies.google.com/privacy",
    cookies: "Cookies",
    cookies_content: "Our website uses so-called cookies. These are little text files, that are stored on your computer by " +
      "your browser and do not harm your computer in any way. " +
      "We use cookies, to adjust the website optimally to your interests. The cookies are stored on your computer until " +
      "you delete them. They allow us to recognize your browser on the next visit. " +
      "If you don't want that, please change your browser settings to ask for your permission before storing cookies. This way the " +
      "browser informs you beforehand and you can enable cookies selectively. Or you can click on the following link:",
    cookies_content_2: "This doesn't prevent the usage of cookies related to the contact form (see paragraphs reCaptcha and contact). " +
      "Please note, however, that if you do this, you may not be able to use the full functionality of this website. " +
      "If you accepted cookies on this website in the past, " +
      "you have to delete them by yourself after a successful deactivation.",
    deactivate_cookies: "Disable Cookies",
    cookies_deactivated: "Cookies were disabled",
    contact_form: "Contact",
    contact_form_content: "If you contact us through or contact form, by email or by phone, your information together with your contact " +
      "data will be stored by us for processing. We will never share your data without your permission. The contact form uses Google " +
      "reCaptcha to prevent spam. The reCaptcha input field and as a consequence thereof the data transport to Google is only activated " +
      "after accepting the privacy policy. If you don't like your data being submitted to Google for contacting us, please contact " +
      "us by email or by phone.",
    privacy_statement: "Protection of your personal data is of utmost interest for us. We process your data exclusively based on " +
      "the legal requirements (DSGVO, TKG 2003). These privacy policy statement informs you about the major aspects of data " +
      "processing within the scope of our website.",
    privacy_rights: "Your Rights",
    privacy_rights_content: "Concerning your by us processed and saved data you have the rights for disclosure, correction, deletion, " +
      "constraint, transfer, revocation and dissent. If you think, that the processing of your data breaks the data protection law " +
      "or your claims in terms of data protection law have been violated in any way, you can raise a complaint under the email address ",
    privacy_rights_content_2: " or direct the complaint to the data protection authority.",
    bank_name: "Sparkasse Baden",
    iban: "IBAN",
    iban_content: "AT87 2020 5010 0005 0086",
    bic: "BIC",
    bic_content: "SPBDAT21XXX",
    agb_view_as_pdf: "View General Business Terms and Conditions as PDF"
  },
  ERRORS: {
    get_lang_error: "Could not get language strings",
    get_blogposts_error: "Could not get blogposts",
    mailing_failed: "Sending message failed, please try again."
  }
};

export default TRANSLATIONS;
