import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { Loading, Notification } from "element-ui";
import { ElLoadingComponent } from "element-ui/types/loading";
import { bootstrap } from 'vue-gtag'

const en = require("element-ui/lib/locale/lang/en");
const de = require("element-ui/lib/locale/lang/de");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: "",
    loading: {},
    loadingCount: 0,
    googleAnalyticsConsentGiven: false,
    basicCookieConsentGiven: false
  },
  mutations: {
    startLoading(state, opacity) {
      if (state.loadingCount < 0) state.loadingCount = 0;
      state.loadingCount++;
      if (state.loadingCount === 1) {
        state.loading = Loading.service({
          lock: true,
          // if strings not loaded make more opaque
          background: `rgba(0, 0, 0, ${opacity ? opacity : 0.4})`
        });
      }
    },
    stopLoading(state) {
      state.loadingCount--;
      if (state.loadingCount === 0) {
        (<ElLoadingComponent>state.loading).close();
      } else if (state.loadingCount < 0) {
        state.loadingCount = 0;
      }
    },
    showErrorNotification(state, errorMessage) {
      Notification.error({
        title: "Error",
        message: errorMessage,
        duration: 0
      });
    },
    showSuccessNotification(state, message) {
      Notification.success({
        title: "Info",
        message
      });
    },
    acceptBasicCookieConsent(state, vueObject) {
      state.basicCookieConsentGiven = true;
      vueObject.$gtag.event("accept", {event_category: "cookieConsent"});
    },
    denyBasicCookieConsent(state, vueObject) {
      vueObject.$gtag.event("deny", {event_category: "cookieConsent"});
      state.basicCookieConsentGiven = false;
    },
    acceptGAConsent(state, vueObject) {
      state.googleAnalyticsConsentGiven = true;
      bootstrap().then(() => {
        vueObject.$gtag.optIn();
      });
      vueObject.$gtag.event("accept", {event_category: "gaConsent"});
    },
    denyGAConsent(state, vueObject) {
      vueObject.$gtag.event("deny", {event_category: "gaConsent"});
      state.googleAnalyticsConsentGiven = false;
      vueObject.$gtag.optOut();
    },
    setLanguage(state, options) {
      state.language = options.language;
      if (options.language === "de") {
        options.vue.$el_locale.use(de);
      } else {
        options.vue.$el_locale.use(en);
      }
    }
  },
  actions: {
    /*setLanguage({commit, state}, payload) {
      return new Promise(async (resolve, reject) => {
      });
    }*/
  },
  plugins: [createPersistedState({
    key: "meam733nk5sv783sk4cn4vb7a8en45el48sd2fs7f789g3h",
    paths: ["language", "strings", "loadingCount", "blogPosts", "googleAnalyticsConsentGiven", "basicCookieConsentGiven"],
    setState: (key, state, storage) => {
      return storage.setItem(key, JSON.stringify(state));
    }
  })],
});
