
import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import CookiePopup from "@/components/CookiePopup.vue";
import BackToTop from "vue-backtotop";

@Component({
  components: {
    Navigation,
    Footer,
    BackToTop,
    CookiePopup
  },
})
export default class App extends Vue {
  public logAnalytics() {
    (<any>this).$gtag.event("overlay_to_top_button", {event_category: "scrollNavigation"});
  }
}
