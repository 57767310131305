
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueTyper } from "vue-typer";
import ParallaxCard from "@/components/ParallaxCard.vue";
import Constants from "@/constants/constants";

@Component({
  components: {
    VueTyper,
    ParallaxCard
  },
})
export default class Title extends Vue {
  @Prop() private STRINGS!: any;
  private minHeightPx = 461; // adapt when finally style
  private minHeightWidescreen = 591; // adapt when finally style
  private height = `${Math.max(window.innerHeight - Constants.NAVBAR_COLLAPSED_HEIGHT -
    Constants.NAVBAR_EXTENSION_HEIGHT, this.minHeightPx)}px`;
  private width = `${window.innerWidth}px`;
  public openFullScreen() {
    this.$store.commit("startLoading");
    setTimeout(() => {
      this.$store.commit("stopLoading");
    }, 1000);
  }

  created() {
    this.initHeight();
  }

  scrolledResizedOrOrientationChange() {
    this.initHeight();
    this.width = `${window.innerWidth}px`;
  }

  initHeight() {
    const smallScreen = window.innerWidth <= Constants.SMALL_SCREEN_MAX_WIDTH;
    if (!smallScreen) {
      this.height = `${Math.max(window.innerHeight - Constants.NAVBAR_COLLAPSED_HEIGHT -
        Constants.NAVBAR_EXTENSION_HEIGHT, this.minHeightWidescreen)}px`;
    } else {
      this.height = `${Math.max(window.innerHeight - Constants.NAVBAR_COLLAPSED_HEIGHT -
        Constants.NAVBAR_EXTENSION_HEIGHT, this.minHeightPx)}px`;
    }
  }

  public async testNotification() {
    try {
      this.$store.commit("showErrorNotification");
    } catch (err) {
      console.error(err);
    }
  }

  public scrollDownAndLogAnalytics() {
    (<any>this).$scrollTo("#servicesSection", 500, {
      offset: -(Constants.NAVBAR_COLLAPSED_HEIGHT + Constants.NAVBAR_EXTENSION_HEIGHT)
    });
    (<any>this).$gtag.event("title_scroll_down_button", {event_category: "scrollNavigation", event_label: this.STRINGS.ROUTE_NAMES.language});
  }
}
