
import { Component, Vue } from "vue-property-decorator";
import Title from "../../components/Title.vue";
import Card from "../../components/Card.vue";
import SectionHeader from "../../components/SectionHeader.vue";
import Navigation from "../../components/Navigation.vue"; // @ is an alias to /src
import ParallaxCard from "../../components/ParallaxCard.vue";
import STRINGS from "@/constants/i18n/strings_frontend_en";
import Footer from "@/components/Footer.vue";
import CookiePopup from "@/components/CookiePopup.vue";
import PartnerSection from "@/components/PartnerSection.vue";

@Component({
  components: {
    Title,
    Card,
    SectionHeader,
    Navigation,
    PortfolioSection: () => import("@/components/PortfolioSection.vue"),
    PartnerSection: () => import("@/components/PartnerSection.vue"),
    AppsSection: () => import("@/components/AppsSection.vue"),
    ServicesSection: () => import("@/components/ServicesSection.vue"),
    ContactSection: () => import("@/components/ContactSection.vue"),
    ParallaxCard,
    Footer,
    CookiePopup
  },
})
export default class Home extends Vue {
  private STRINGS = STRINGS;
  private portfolioEnabled = true;
  private homeHeader = document.getElementById("homeSection");
  private servicesHeader = document.getElementById("servicesSection");
  private aboutUsHeader = document.getElementById("aboutUsSection");
  private portfolioHeader = document.getElementById("portfolioSection");
  private contactHeader = document.getElementById("contactSection");
  private lastHeaderInViewport: any;

  created() {
    (<any>document.head.querySelector('meta[name="description"]')).content = "Custom software engineering made " +
      "easy with Lines of Future Solutions. We offer services in the scope of cloud technologies, blockchain " +
      "and smart contract engineering, machine learning, security, web & app development, consulting et cetera. " +
      "Be the future!";

    (<any>document.head.querySelector('meta[name="og:description"]')).content = "Custom software engineering made " +
      "easy with Lines of Future Solutions. We offer services in the scope of cloud technologies, blockchain " +
      "and smart contract engineering, machine learning, security, web & app development, consulting et cetera. " +
      "Be the future!";

    (<any>document.querySelector("html")).setAttribute("lang", "en");

    this.portfolioEnabled = process.env.VUE_APP_PORTFOLIO_ENABLED === "true";
  }

  destroyed() {
    window.removeEventListener("scroll", this.checkHeadersInViewport, false);
  }

  mounted() {
    this.homeHeader = document.getElementById("homeSection");
    this.servicesHeader = document.getElementById("servicesSection");
    this.aboutUsHeader = document.getElementById("aboutUsSection");
    this.portfolioHeader = document.getElementById("portfolioSection");
    this.contactHeader = document.getElementById("contactSection");
    this.checkHeadersInViewport();
    window.addEventListener("scroll", this.checkHeadersInViewport, false);
  }

  checkHeadersInViewport() {
    if (this.isInViewport(this.homeHeader)) {
      if (this.lastHeaderInViewport !== this.homeHeader) {
        setTimeout(() => {
          if (this.isInViewport(this.homeHeader)) {
            (<any>this).$gtag.event("homeSection", {event_category: "inViewport", event_label: this.STRINGS.ROUTE_NAMES.language});
          }
        }, 1500);
        this.lastHeaderInViewport = this.homeHeader;
      }
    } else if (this.isInViewport(this.servicesHeader)) {
      if (this.lastHeaderInViewport !== this.servicesHeader) {
        setTimeout(() => {
          if (this.isInViewport(this.servicesHeader)) {
            (<any>this).$gtag.event("servicesSection", {event_category: "inViewport", event_label: this.STRINGS.ROUTE_NAMES.language});
          }
        }, 1500);
        this.lastHeaderInViewport = this.servicesHeader;
      }
    } else if (this.isInViewport(this.aboutUsHeader)) {
      if (this.lastHeaderInViewport !== this.aboutUsHeader) {
        setTimeout(() => {
          if (this.isInViewport(this.aboutUsHeader)) {
            (<any>this).$gtag.event("aboutUsSection", {event_category: "inViewport", event_label: this.STRINGS.ROUTE_NAMES.language});
          }
        }, 1500);
        this.lastHeaderInViewport = this.aboutUsHeader;
      }
    } else if (this.isInViewport(this.portfolioHeader)) {
      if (this.lastHeaderInViewport !== this.portfolioHeader) {
        setTimeout(() => {
          if (this.isInViewport(this.portfolioHeader)) {
            (<any>this).$gtag.event("portfolioSection", {event_category: "inViewport", event_label: this.STRINGS.ROUTE_NAMES.language});
          }
        }, 1500);
        this.lastHeaderInViewport = this.portfolioHeader;
      }
    } else if (this.isInViewport(this.contactHeader)) {
      if (this.lastHeaderInViewport !== this.contactHeader) {
        setTimeout(() => {
          if (this.isInViewport(this.contactHeader)) {
            (<any>this).$gtag.event("contactSection", {event_category: "inViewport", event_label: this.STRINGS.ROUTE_NAMES.language});
          }
        }, 1500);
        this.lastHeaderInViewport = this.contactHeader;
      }
    }
  }

  isInViewport(elem: HTMLElement | null) {
    if (!elem) return false;
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
