
import { Component, Prop, Vue } from "vue-property-decorator";
import Constants from "@/constants/constants";

@Component
export default class Card extends Vue {
  private el: any;
  private elemId = `${Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER))}`;
  @Prop({default: 0.5}) private speed!: number;
  @Prop() private image!: string;
  @Prop({default: "100%"}) private height!: string;
  private isPortrait = false;
  private wasLastResizing = false;
  onScroll() {
    if (window.innerWidth >= Constants.PARALLAX_MIN_WIDTH && window.innerHeight >= Constants.PARALLAX_MIN_HEIGHT) {
      const pos = window.pageYOffset;
      const top = this.el.offsetTop;
      const height = this.el.clientHeight;

      if (top + height < pos || top > pos + window.innerHeight) {
        return; // not in view, do not transform
      }
      this.el.style.backgroundPosition = `50% ${Math.round((top - pos) * this.speed)}px`;
    } else {
      this.el.style.backgroundPosition = `center center`;
    }
    let orientationChanged = false;
    if (this.isPortrait && window.innerHeight < window.innerWidth) {
      this.isPortrait = false;
      orientationChanged = true;
    } else if (!this.isPortrait && window.innerHeight > window.innerWidth) {
      this.isPortrait = true;
      orientationChanged = true;
    }
    if (window.innerWidth >= Constants.PARALLAX_MIN_WIDTH && window.innerHeight >= Constants.PARALLAX_MIN_HEIGHT) {
      this.wasLastResizing = true;
      this.$emit("scrolledResizedOrOrientationChange");
    } else if (this.wasLastResizing) {
      this.wasLastResizing = false;
      this.$emit("scrolledResizedOrOrientationChange");
    } else if (orientationChanged) {
      this.$emit("scrolledResizedOrOrientationChange");
    } else {
      this.wasLastResizing = false;
    }
  }

  created() {
    window.addEventListener("scroll", this.onScroll, false);
    window.addEventListener("resize", this.onScroll, false);
  }
  mounted() {
    this.el = this.$refs[this.elemId];
    this.isPortrait = window.innerHeight > window.innerWidth;
    this.onScroll();
  }
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
    window.removeEventListener("resize", this.onScroll, false);
  }
}
