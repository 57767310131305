
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Constants from "@/constants/constants";

@Component
export default class Navigation extends Vue {
  @Prop({default: false}) private collapsed!: boolean;
  @Prop({default: "auto"}) private collapseType!: string;
  @Prop() private customItems!: any;
  @Prop() private STRINGS!: any;
  private collapsedInternal = false;
  private debouncing = false;
  private showCollapse = false;
  onScroll() {
    if (document.documentElement.scrollTop === 0 || document.documentElement.scrollTop > (Constants.NAVBAR_EXTENSION_HEIGHT + Constants.NAVBAR_COLLAPSED_HEIGHT)) {
      this.collapsedInternal = document.body.scrollTop > (Constants.NAVBAR_EXTENSION_HEIGHT + Constants.NAVBAR_COLLAPSED_HEIGHT)
        || document.documentElement.scrollTop > (Constants.NAVBAR_EXTENSION_HEIGHT + Constants.NAVBAR_COLLAPSED_HEIGHT);
    }
  }

  onResize() {
    // for making navbar scrollable if window height not high enough
   const navBarCollapseElem: HTMLElement | null = document.getElementById("nav_collapse");
   if (navBarCollapseElem) { navBarCollapseElem.style.maxHeight
     = `${window.innerHeight - (Constants.NAVBAR_EXTENSION_HEIGHT + Constants.NAVBAR_COLLAPSED_HEIGHT)}px`;
   }
  }

  @Watch("collapsed")
  onCollapsedExternalChanged(val: boolean) {
    this.collapsedInternal = val;
  }

  onClickedOut(evt: Event) {
    let toggleClicked = false;
    for (const p of evt.composedPath()) {
      if (p && (<any> p).className && (<any> p).className.includes("navbar-toggler")) {
        toggleClicked = true;
        break;
      }
    }
    if (!toggleClicked) this.showCollapse = false;
  }

  onClickedOutMobile(evt: Event) {
    let navCLicked = false;
    let toggleClicked = false;
    for (const p of evt.composedPath()) {
      if ((<any> p).id === "nav_collapse") navCLicked = true;
      if (p && (<any> p).className && (<any> p).className.includes("navbar-toggler")) toggleClicked = true;
    }
    if (!navCLicked && !toggleClicked) this.showCollapse = false;
  }

  async created() {
    if (this.collapseType !== "manual") {
      window.addEventListener("scroll", this.onScroll, false);
    }
    window.addEventListener("resize", this.onResize, false);
    window.addEventListener("click", this.onClickedOut, false);
    window.addEventListener("touchstart", this.onClickedOutMobile, false);

    let foundLanguage = "";
    for (const l of this.STRINGS.ROUTE_NAMES.languages.other) {
      if (this.$route.path.startsWith(`/${l}`)) {
        foundLanguage = l;
      }
    }
    if (!this.$store.state.language) {
      // disabled auto-selecting language, enable again by uncommenting following section
      // but beware, not working with prerender-spa-plugin
      /*const lang = navigator.language;
      if (lang.indexOf("de") !== -1) {
        this.$store.commit("setLanguage", {language: "de", vue: this});
        if (this.STRINGS.ROUTE_NAMES.language !== "de") await this.setLanguage("de");
      } else {
        this.$store.commit("setLanguage", {language: "en", vue: this});
        if (this.STRINGS.ROUTE_NAMES.language !== "en") await this.setLanguage("en");
      }*/
    } else {
      await this.setLanguage(this.$store.state.language, true);
    }
    this.onResize();
  }

  destroyed() {
    if (this.collapseType !== "manual") {
      window.removeEventListener("scroll", this.onScroll, false);
    }
    window.removeEventListener("resize", this.onResize, false);
    window.removeEventListener("click", this.onClickedOut, false);
    window.removeEventListener("touchstart", this.onClickedOutMobile, false);
  }

  public async setLanguage(lang: string, selfCalled: boolean) {
    if (!selfCalled) {
      (<any>this).$gtag.event(lang, {event_category: "setLanguage"});
    }
    let foundLanguage = "";
    for (const l of this.STRINGS.ROUTE_NAMES.languages.other) {
      if (this.$route.path.startsWith(`/${l}`)) {
        foundLanguage = l;
      }
    }
    if (foundLanguage) {
      // we are in non-default language route
      if (this.STRINGS.ROUTE_NAMES.languages.default === lang) {
        // target is default language
        if (!selfCalled) this.$store.commit("setLanguage", {language: this.STRINGS.ROUTE_NAMES.languages.default, vue: this});
        this.$router.push(this.ensureEndsWithSlash(this.$route.path.replace(`/${foundLanguage}`, "/").replace("//", "/"))).catch(()=>{});
      } else {
        // target is non-default language
        if (!selfCalled) this.$store.commit("setLanguage", {language: lang, vue: this});
        this.$router.push(this.ensureEndsWithSlash(this.$route.path.replace(`/${foundLanguage}`, `/${lang}`).replace("//", "/"))).catch(()=>{});
        /*const toGo = this.$route.path.replace(`/${foundLanguage}`, `/${lang}`).replace("//", "/");
        this.$router.push(toGo.endsWith("/") ? toGo.substring(0, toGo.length - 1) : toGo);*/
      }
    } else {
      // we are in default language route
      if (this.STRINGS.ROUTE_NAMES.languages.default === lang) {
        // target is default language
        if (!selfCalled) this.$store.commit("setLanguage", {language: this.STRINGS.ROUTE_NAMES.languages.default, vue: this});
        this.$router.push(this.ensureEndsWithSlash(`${this.$route.path}`.replace("//", "/"))).catch(()=>{});
      } else {
        // target is non-default language
        if (!selfCalled) this.$store.commit("setLanguage", {language: lang, vue: this});
        this.$router.push(this.ensureEndsWithSlash(`/${lang}${this.$route.path}`.replace("//", "/"))).catch(()=>{});
        /*const toGo = `/${lang}${this.$route.path}`.replace("//", "/");
        this.$router.push(toGo.endsWith("/") ? toGo.substring(0, toGo.length - 1) : toGo);*/
      }
    }
  }

  public scrollToAndLogAnalytics(elem: string, key: string) {
    (<any>this).$scrollTo(elem, 500, {
      offset: this.collapsedInternal
        ? -Constants.NAVBAR_COLLAPSED_HEIGHT
        : -(Constants.NAVBAR_COLLAPSED_HEIGHT + Constants.NAVBAR_EXTENSION_HEIGHT)
    });
    (<any>this).$gtag.event(key, {event_category: "scrollNavigation", event_label: this.STRINGS.ROUTE_NAMES.language});
  }

  setCollapsedInternalDebounced(value: boolean) {
    const _self = this;
    if (!this.debouncing) {
      this.debouncing = true;
      this.collapsedInternal = value;
      setTimeout(() => {
        _self.debouncing = false;
      }, 50);
    }
  }

  ensureEndsWithSlash(str: string) {
    if (!str.endsWith("/")) return `${str}/`;
    return str;
  }
}
