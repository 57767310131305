
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  @Prop() private STRINGS!: any;

  public logAnalytics(key: string) {
    (<any>this).$gtag.event(key, {event_category: "social", event_label: this.STRINGS.ROUTE_NAMES.language});
  }

}
