/**
 * API endpoints
 */
export default class Constants {
  public static readonly NAVBAR_COLLAPSED_HEIGHT: number = 59; // like in global.scss
  public static readonly NAVBAR_EXTENSION_HEIGHT: number = 0; // like in global.scss
  public static readonly SMALL_SCREEN_MAX_WIDTH: number = 767; // like in global.scss
  public static readonly PARALLAX_MIN_WIDTH: number = 576;
  public static readonly PARALLAX_MIN_HEIGHT: number = 520;
  public static readonly NAME_MIN_LENGTH: number = 3;
  public static readonly NAME_MAX_LENGTH: number = 150;
  public static readonly EMAIL_MIN_LENGTH: number = 5;
  public static readonly EMAIL_MAX_LENGTH: number = 150;
  public static readonly SUBJECT_MIN_LENGTH: number = 3;
  public static readonly SUBJECT_MAX_LENGTH: number = 150;
  public static readonly MESSAGE_MIN_LENGTH: number = 5;
  public static readonly MESSAGE_MAX_LENGTH: number = 2000;
  public static readonly TEL_MIN_LENGTH: number = 5;
  public static readonly TEL_MAX_LENGTH: number = 30;
}