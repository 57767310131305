import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Button } from "element-ui";
import { NavbarPlugin, LayoutPlugin, BImgLazy, FormPlugin, FormTextareaPlugin,
  FormInputPlugin, FormGroupPlugin, FormSelectPlugin, CollapsePlugin, CarouselPlugin, FormCheckboxPlugin } from "bootstrap-vue";
import "./global.scss";
import VueScrollTo from "vue-scrollto";
import VueGtag from "vue-gtag";

const en = require("element-ui/lib/locale/lang/en");
const locale = require("element-ui/lib/locale");

Vue.config.productionTip = false;

// configure language of element-ui
locale.use(en);

// use specified element-ui components and services
// if importing additional stuff from element-ui here, import also specific scss files in global.scss
Vue.component(Button.name, Button);
Vue.prototype.$el_locale = locale;

// if importing additional stuff from bootstrap, import also specific scss files in global.scss
Vue.use(NavbarPlugin);
Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(CollapsePlugin);
Vue.use(CarouselPlugin);
Vue.component("b-img-lazy", BImgLazy);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -136,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.use(VueGtag, {
  bootstrap: store.state.googleAnalyticsConsentGiven,
  enabled: process.env.NODE_ENV === "production",
  config: {
    id: "G-5XNB0T23ZP",
    params: {
      anonymize_ip: true
    }
  }
}, router);

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: () => {
    setTimeout(() => {
      document.dispatchEvent(new Event("x-app-rendered"));
    }, 3000);
  }
}).$mount("#app");
