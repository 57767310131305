import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=31e87c14&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=ts&"
export * from "./Card.vue?vue&type=script&lang=ts&"
import style0 from "./Card.vue?vue&type=style&index=0&id=31e87c14&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e87c14",
  null
  
)

export default component.exports